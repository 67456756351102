<script lang="ts" setup>
	import type { IUser } from "~/server/models/user";
	import { ClientReloadPolicy } from "~~/services/store";
	import type { StoreRecordData } from "~~/services/store";

	const props = defineProps<{
		id: string;
		reloadOnClient?: ClientReloadPolicy;
	}>();

	const id = toRef(props, "id");

	const { record, operation, task } = useFindRecord<StoreRecordData<IUser>>(ref("users"), id, {
		reloadOnClient: props.reloadOnClient ?? ClientReloadPolicy.never,
	});

	if (process.server && task.value) {
		onServerPrefetch(async () => {
			await task.value;
		});
	}
</script>

<template>
	<slot :record="record" :operation="operation"></slot>
</template>
